import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/chat-channel/Layout";
import Container from "../components/chat-channel/Container";
import { Link } from "gatsby";
import { useMedia } from "use-media";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestFormModal } from "../components/modalform";
import { RequestForm } from "../components/form";
// import CookieConsent from "react-cookie-consent";

import {
  useUniqueId,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from "../components/useDisableBodyScroll";

const assistant = require("../assets/images/homepage/assistant_icon_v2.svg");
const automate = require("../assets/images/homepage/automate_icon.svg");
const Clouds = require("../assets/images/homepage/cloud_bg.svg");
const Clouds_mobile = require("../assets/images/homepage/cloud_bg_mob.svg");
const bannerImage = require("../assets/img/feature/home/workativ_main_logos.svg");
const bannerImageMobile = require("../assets/img/feature/home/workativ_main_logos_mob.svg");
const img = require("../assets/img/feature/home/iso_sec_img.png");
const img_mob = require("../assets/img/feature/home/iso_mob_automate_home.svg");

const star_image = require("../assets/images/testimonials/star.svg");
const capterra_logo = require("../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../assets/images/testimonials/g2_logo.svg");
const quotes = require("../assets/images/testimonials/quotes.svg");
const goto_logo = require("../assets/images/testimonials/goto_logo_mob.svg");
const close = require("../assets/images/testimonials/close_icon.svg");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ",
  url: "https://workativ.com",
  description:
    " Workativ Assistant is easy to use 'AI + Automation' SaaS product for companies to build an intelligent Virtual Assistant/Chatbot with pre-built automations, to provide employee support, reduce Help Desk costs, and increase productivity. No coding required! ",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support. ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};
export default function HomePage() {
  const [plan, setPlan] = useState("");

  const [showThanksPage, setShowThanksPage] = useState(false);
  return (
    <>
      <TitleAndMetaTags
        title="Workativ - No-code Workflow Automation, App Integration, and Conversational AI Chatbot SaaS for Workplace Support Automation."
        description="Reduce manual work and transform performance at the workplace with workflow automation platform and conversational AI technology that empowers your organization to improve employee service and create positive outcomes."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        homepagrUrl
        schema={ldSchema}
      />
      <div className="mob_img_home resolution_automate_first home_page_new_workativ">
        <Container>
          <Layout backgroundColor={""} logoFor="WORKATIV" product="WORKATIV">
            <HomePageTop />
            <TwoCard />
            <RatingHomePage />
            <ISO />

            {plan == "" ? null : (
              <RequestFormModal
                setPlan={setPlan}
                plan={plan}
                showThanksPage={showThanksPage}
                setShowThanksPage={setShowThanksPage}
                fromPage="workativ-home"
              />
            )}
            {/* <GoToPopup />
            <CookiesPoup /> */}
          </Layout>
        </Container>
      </div>
    </>
  );
}
function HomePageTop() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="width-100 float-left assistant-main-home-page-container">
      <div className="container">
        <div className="d-flex flex-container-top-home">
          <div className="col-md-12 align-self-center home-page-slider-left-container">
            {isSmall ? (
              <>
                <h1 className="font-page-header-home text-align-center">
                  Automate your workplace with chatbot and app workflows
                </h1>
                <p className="font-section-normal-text text-align-center">
                  Build AI Chatbots without code, automate workflows, and
                  improve employee experience. Give your employees the best of
                  both AI and Automation.
                </p>
              </>
            ) : (
              <>
                <h1 className="font-page-header-home text-align-center">
                  Automate your workplace with chatbot<br></br> and app
                  workflows
                </h1>
                <p className="font-section-normal-text text-align-center">
                  Build AI Chatbots without code, automate workflows, and
                  improve employee experience.<br></br>Give your employees the
                  best of both AI and Automation.
                </p>
              </>
            )}
            <RequestForm isFooterForm={false} fromPage="workativ-home"/>
            <CustomerBanner />
          </div>
        </div>
      </div>
      {isSmall ? <img src={Clouds_mobile}></img> : <img src={Clouds}></img>}
    </section>
  );
}
// export function CookiesPoup() {
//   const COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY =
//     "COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY";

//   const [popupVisiblity, setPopupVisiblity] = useState(true);

//   const POPUP_EXPIRY_IN_MINUTES = 50 * 60 * 1000;

//   const onClickClose = () => {
//     setPopupVisiblity(false);
//     setLocalStorageWithExpiry(
//       COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
//       { route: window.location.href, componentId: uniqueId },
//       POPUP_EXPIRY_IN_MINUTES
//     );
//   };

//   const uniqueId = useUniqueId();

//   // console.log("GoToPopup :: storedKey",uniqueId)

//   const storedKey = getLocalStorageWithExpiry(
//     COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY
//   );

//   const cookiePopupShownAlready =
//     storedKey == null || storedKey == undefined || storedKey == ""
//       ? "not-shown"
//       : `shown::${storedKey.componentId}`;

//   // console.log("GoToPopup :: storedKey", storedKey, gotoPopupShownAlready)
//   // React.useEffect(() => {
//   //   if (cookiePopupShownAlready === "not-shown" && popupVisiblity) {
//   //     setLocalStorageWithExpiry(
//   //       COOKIE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
//   //       { route: window.location.href, componentId: uniqueId },
//   //       POPUP_EXPIRY_IN_MINUTES
//   //     );
//   //   }
//   // }, [popupVisiblity]);

//   if (popupVisiblity && cookiePopupShownAlready === "not-shown") {

//     return (<div className="cookies_popup_container">
//       <p className="font-name-smaller-text mb-0 pb-0 text-align-center">
//         This website uses cookies to improve your experience. By using this
//         site, you agree with our use of cookies.
//       </p>
//       <button className="cookies_popup_button" onClick={onClickClose}>
//         <span className="font-name-smaller-text-medium">Accept</span>
//       </button>
//     </div>)
//   }

//   return null
// }
function TwoCard() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="width-100 float-left card-box-section-container">
      {isSmall ? (
        <div className="d-flex card-box-home-main-container">
          <div className="card-assistant-box col-md-6">
            <div className="card-assistant-box-top-header d-flex">
              <img src={assistant} loading="lazy" />
              <h3 className="font-section-sub-header mb-0">
                Workativ Assistant
              </h3>
            </div>
            <h5 className="font-section-sub-header-small">
              Conversational AI Chatbot Platform
            </h5>
            <p className="font-section-normal-text card_assistants_box_paragraph">
              Deliver faster and modern HR or IT Support, powered by ‘AI chatbot
              + app workflow automation’ in a single platform solution. Augment
              your support teams with chatbot and scale your employee support in
              minutes.
            </p>
            <p className="font-section-normal-text card_assistants_box_paragraph_two">
              Go live in minutes. No coding.
            </p>
            <Link
              to="/conversational-ai-platform"
              className="url_manipulation font-section-normal-text cursor-pointer"
            >
              <button>
                Learn more <span>&#8594;</span>
              </button>
            </Link>
          </div>

          <div className="card-automate-box col-md-6">
            <div className="card-automate-box-top-header d-flex">
              <img src={automate} />

              <h3 className="font-section-sub-header mb-0">
                Workativ Automate
              </h3>
            </div>
            <h5 className="font-section-sub-header-small">
              Workflow Automation Platform
            </h5>
            <p className="font-section-normal-text card_automate_box_paragraph">
              A modern workflow automation platform to help you connect your
              apps, and automate your workplace tasks in minutes. It’s easy to
              use yet powerful, and affordable to all.
            </p>
            <p className="font-section-normal-text card_automate_box_paragraph_two">
              Go live in minutes. No coding.
            </p>
            <Link
              to="/workflow-automation-platform"
              className="url_manipulation font-section-normal-text cursor-pointer"
            >
              <button>
                Learn more <span>&#8594;</span>
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="d-flex card-box-home-main-container">
            <div className="card-assistant-box col-md-6">
              <div className="card-assistant-box-top-header d-flex">
                <img src={assistant} />
                <h3 className="font-section-sub-header mb-0">
                  Workativ Assistant
                </h3>
              </div>
              <h5 className="font-section-sub-header-small">
                Conversational AI Chatbot Platform
              </h5>
              <p className="font-section-normal-text card_assistants_box_paragraph">
                Deliver faster and modern HR or IT Support, powered by ‘AI
                chatbot + app workflow automation’ in a single platform
                solution. Augment your support teams with chatbot and scale your
                employee support in minutes.
              </p>
              <p className="font-section-normal-text card_assistants_box_paragraph_two">
                Go live in minutes. No coding.
              </p>
              <Link
                to="/conversational-ai-platform"
                className="url_manipulation font-section-normal-text cursor-pointer"
              >
                <button>
                  Learn more <span>&#8594;</span>
                </button>
              </Link>
            </div>

            <div className="card-automate-box col-md-6">
              <div className="card-automate-box-top-header d-flex">
                <img src={automate} />

                <h3 className="font-section-sub-header mb-0">
                  Workativ Automate
                </h3>
              </div>
              <h5 className="font-section-sub-header-small">
                Workflow Automation Platform
              </h5>
              <p className="font-section-normal-text card_automate_box_paragraph">
                A modern workflow automation platform to help you connect your
                apps, and automate your workplace tasks in minutes. It’s easy to
                use yet powerful, and affordable to all.
              </p>
              <p className="font-section-normal-text card_automate_box_paragraph_two">
                Go live in minutes. No coding.
              </p>
              <Link
                to="/workflow-automation-platform"
                className="url_manipulation font-section-normal-text cursor-pointer"
              >
                <button>
                  Learn more <span>&#8594;</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
function CustomerBanner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <div
        className="float-left customer-banner-home-main-container"
        style="width: 100%;"
      >
        <div className="container join-banner-cutomer-home">
          <div className="d-flex customer-home-container-new">
            <h5 className="text-center margin-bottom-20 font-section-sub-header-small-home">
              Join hundreds of industry-leader
            </h5>{" "}
            {isSmall ? (
              <img className="" src={bannerImageMobile} />
            ) : (
              <img className="" src={bannerImage} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export function RatingHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section class="testimonial_background_main_container testimonial_background_rating_padding_mobile float-left testimonial_background_main_container-home-page">
      <div className="container">
        <div className="row">
          <div className="rating_box_main_container">
            <div className="rating_left_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span className="font-section-small-text">5/5</span>
              </div>
              <p className="font-section-normal-text-testimonials rating_paragraph_content line-height-18">
                <span>
                  <img
                    src={quotes}
                    loading="lazy"
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides.
              </p>

              <img className="capterra_logo_left" src={capterra_logo} />
            </div>
            <div className="rating_right_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span className="font-section-small-text">5/5</span>
              </div>
              <p className="font-section-normal-text-testimonials rating_paragraph_content line-height-18">
                <span>
                  <img
                    src={quotes}
                    loading="lazy"
                    className="quotes_image_testimonial"
                  ></img>
                </span>
                The deployment and support team was exceptional! They worked
                hard to make sure our rollout was successful.
              </p>
              <img src={g2_logo} className="g2_logo_right"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function ISO() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <section className="center_image_home cards_landing_page pb-0 mb-0">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="main-slider-left">
                <h3 className="font-section-header">
                  Your data is yours and you control it
                </h3>
                <p className="font-section-normal-text">
                  Workativ has implemented robust security processes and
                  controls that are in compliance with industry-leading
                  standards and regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="automation_steps iso-image-section-home iso">
        <div className="container">
          {isSmall ? (
            <picture>
              <img loading="lazy" className="center" src={img_mob} />
            </picture>
          ) : (
            <picture>
              <img loading="lazy" className="center" src={img} />
            </picture>
          )}
        </div>
      </section>
    </>
  );
}
// export function GoToPopup() {
//   // console.log("GoToPopup")
//   const GOTO_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY =
//     "GOTO_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY";
//   const isSmall = useMedia({ maxWidth: "520px" });
//   const [popupVisiblity, setPopupVisiblity] = useState(true);

//   const POPUP_EXPIRY_IN_MINUTES = 5 * 60 * 1000;

//   const onClickClose = () => {
//     setPopupVisiblity(false);
//     setLocalStorageWithExpiry(
//       GOTO_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
//       { route: window.location.href, componentId: uniqueId },
//       POPUP_EXPIRY_IN_MINUTES
//     );
//   };

//   const uniqueId = useUniqueId();

//   // console.log("GoToPopup :: storedKey",uniqueId)

//   const storedKey = getLocalStorageWithExpiry(
//     GOTO_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY
//   );

//   const gotoPopupShownAlready =
//     storedKey == null || storedKey == undefined || storedKey == ""
//       ? "not-shown"
//       : `shown::${storedKey.componentId}`;

//   // console.log("GoToPopup :: storedKey", storedKey, gotoPopupShownAlready)
//   // React.useEffect(() => {
//   //   if (gotoPopupShownAlready === "not-shown" && popupVisiblity) {
//   //     setLocalStorageWithExpiry(
//   //       GOTO_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
//   //       { route: window.location.href, componentId: uniqueId },
//   //       POPUP_EXPIRY_IN_MINUTES
//   //     );
//   //   }
//   // }, [popupVisiblity]);

//   return (
//     <section className="width-100 float-left">
//       {popupVisiblity && gotoPopupShownAlready === "not-shown" ? (
//         <div className="goto_popup_case">
//           <div className="goto_top_popup_container">
//             <h3 className="font-section-normal-text">Customer spotlight</h3>{" "}
//             <img
//               class="close cursor-pointer close_popup_goto"
//               onClick={onClickClose}
//               src={close}
//             ></img>
//           </div>
//           <div className="goto_bottom_popup_container">
//             {isSmall ? (
//               <h2 className="font-section-normal-text-goto-popup  line-height-18 mt-3 text-align-center">
//                 Learn how{" "}
//                 <span>
//                   <img src={goto_logo} className="goto_logo_goto_popup"></img>
//                 </span>{" "}
//                 uses self-service automation for employee support
//               </h2>
//             ) : (
//               <h2 className="font-section-normal-text-goto-popup  line-height-2 mt-4 text-align-center">
//                 Learn how{" "}
//                 <span>
//                   <img src={goto_logo} className="goto_logo_goto_popup"></img>
//                 </span>{" "}
//                 uses self-service automation for employee support
//               </h2>
//             )}
//             <button
//               className="goto_popu_button"
//               onClick={() => {
//                 window.location.href =
//                   "https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation?utm_source=Popup&utm_medium=Website+banner&utm_campaign=Goto+popup";
//               }}
//             >
//               <span className="font-section-normal-text-medium text-align-center">
//                 Learn more
//               </span>
//             </button>
//           </div>
//         </div>
//       ) : null}
//     </section>
//   );
// }
